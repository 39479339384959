html, body {
  background: #282831;
  color: white;
  height: 100%;
  width: 100%;
}

canvas {
  width: 300px;
  height: 25200px;
}

::-webkit-scrollbar {     
  background-color: #fff;
  width: 1.2em
}

::-webkit-scrollbar-thumb:window-inactive,
::-webkit-scrollbar-thumb {
  background:  darkgray;
}

.upload-file-wrapper {
  --sk-color: #fff;
  display: flex;
}

.upload-file-wrapper .loading {
  display: flex;
  align-items: center;
}